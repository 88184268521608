import { ALERT_LIST_QUERY } from '../graphql/alerts/getAlertList'
import { ALERT_STATE_QUERY } from '../graphql/alerts/getAlertState'
import { LIST_USER_GROUPS_QUERY } from '../graphql/listUserGroups'
import { USER_LIST_QUERY } from '../graphql/getUserList'
import {
  DELETE_USER_MUTATION,
  DELETE_USER_VARIABLES
} from '../graphql/deleteUser'
import {
  USER_INFORMATION_QUERY,
  USER_INFORMATION_VARIABLES
} from '../graphql/getUserInformation'
import {
  UPDATE_USER_MUTATION,
  UPDATE_USER_VARIABLES
} from '../graphql/updateUser'
import {
  INSERT_NEW_USER_MUTATION,
  INSERT_NEW_USER_VARIABLES
} from '../graphql/insertNewUser'
import { DEPLOYED_SERVICES_QUERY } from '../graphql/getDeployedServices'
import { GROUP_LIST_QUERY } from '../graphql/getGroups'
import {
  MARK_ALERT_AS_READ_MUTATION,
  MARK_ALERT_AS_READ_VARIABLES
} from '../graphql/alerts/markAlertAsRead'
import { ROLE_LIST_QUERY } from '../graphql/getRoles'
import {
  UPDATE_GROUP_MUTATION,
  UPDATE_GROUP_VARIABLES
} from '../graphql/updateGroup'
import {
  INSERT_NEW_ROLE_MUTATION,
  INSERT_NEW_ROLE_VARIABLES
} from '../graphql/insertNewRole'
import {
  INSERT_NEW_GROUP_MUTATION,
  INSERT_NEW_GROUP_VARIABLES
} from '../graphql/insertNewGroup'
import {
  DELETE_GROUP_MUTATION,
  DELETE_GROUP_VARIABLES
} from '../graphql/deleteGroup'
import {
  DELETE_ROLE_MUTATION,
  DELETE_ROLE_VARIABLES
} from '../graphql/deleteRole'
import { GET_USERS_QUERY } from '../graphql/getUsers'
import {
  MANAGE_USER_MUTATION,
  MANAGE_USER_VARIABLES
} from '../graphql/manageUser'
import {
  UPDATE_ROLE_MUTATION,
  UPDATE_ROLE_VARIABLES
} from '../graphql/updateRole'
import {
  GROUPNAMES_BYIDS_LIST_QUERY,
  GROUPNAMES_BYIDS_LIST_VARIABLES
} from '../graphql/getGroupNamesByIds'
import { GET_USER_LICENCE_INFO } from '../graphql/getUserLicenceInfo'
import {
  GET_ALL_USER_PERMISSIONS,
  GET_ALL_USER_PERMISSIONS_VARIABLES
} from '../graphql/GetUserPermissionsQuery'
import {
  PERMISSIONS_FOR_ROLE,
  PERMISSIONS_FOR_ROLE_VARIABLES
} from '../graphql/getPermissionsForRole'
import {
  UPDATE_PERMISSIONS_MUTATION,
  UPDATE_PERMISSIONS_VARIABLES
} from '../graphql/updatePermissions'
import { PERMISSION_DESCRIPTORS_QUERY } from '../graphql/getPermissionDescriptors'
import { PERMISSION_STRUCTURE_QUERY } from '../graphql/getPermissionStructure'
import { GET_ALERTS_QUERY } from '../graphql/alerts/getAlerts'
import { GET_ARCHIVED_ALERTS_QUERY } from '../graphql/alerts/getArchivedAlerts'
import {
  CREATE_REACTION_MUTATION,
  CREATE_REACTION_VARIABLES
} from '../graphql/alerts/createReaction_Dev'
import {
  CREATE_COMMENT_VARIABLES,
  CREATE_COMMENT_MUTATION
} from '../graphql/alerts/createComment_Dev'
import {
  ARCHIVE_ALERT_MUTATION,
  ARCHIVE_ALERT_VARIABLES
} from '../graphql/alerts/archiveAlert'
import { CREATED_REACTION_SUBSCRIPTION } from '../graphql/alerts/createdReactionSubscription'
import { CREATED_COMMENT_SUBSCRIPTION } from '../graphql/alerts/createdCommentSubscription'
import { CREATED_ALERT_SUBSCRIPTION } from '../graphql/alerts/createdAlertSubscription'
import { CREATED_ARCHIVED_ALERT_SUBSCRIPTION } from '../graphql/alerts/createdArchivedalertSubscription'
import {
  GET_USER_STORAGE_QUERY,
  GET_USER_STORAGE_VARIABLES
} from '../graphql/alerts/getUserStorage'
import {
  UPDATE_USER_SESSION_MUTATION,
  UPDATE_USER_SESSION_VARIABLES
} from '../graphql/alerts/updateUserSession'
import {
  UPDATE_USER_SETTINGS_MUTATION,
  UPDATE_USER_SETTINGS_VARIABLES
} from '../graphql/alerts/updateUserSettings'
import {
  UPDATED_USER_SETTINGS_SUBSCRIPTION,
  UPDATED_USER_SETTINGS_VARIABLES
} from '@/graphql/alerts/updatedUserSettingsSubscription'
import {
  GET_TRANSLATION_QUERY,
  GET_TRANSLATION_VARIABLES
} from '@/graphql/alerts/getTranslation'

/**
 * The methods within this file help building the graphlql requests that are sent with the ApolloClient to the
 * AppSyncApi.
 */
export default {
  /** REQUEST CONSTRUCTORS */

  /**
   * Query request object builder
   * @param {*} request the actual request (e.g. query or mutation)
   * @param {*} variables variables JSON object used for the request
   * @param {*} pollInterval the polling interval of the request (in which frequence should apollo repeat this
   *     request)
   * @returns {*} the query request object.
   * For example: request = AVAILABLE_CALCULATIONS_QUERY, variables = { identifier: "identifier"}
   * will be built to { query: AVAILABLE_CALCULATIONS_QUERY, variables = { identifier: "identifier"} }
   */
  getGraphqlQueryObject(request, variables, pollInterval) {
    if (pollInterval) {
      return {
        query: request,
        variables: variables,
        pollInterval: pollInterval
      }
    } else {
      return { query: request, variables: variables }
    }
  },
  /**
   * Mutation request object builder
   * @param {*} request
   * @param {*} variables variables JSON object used for the request
   * @returns {*} the mutation request object.
   * For example: request = DELETE_USER_MUTATION, variables = { username: "username"}
   * will be built to { mutation: DELETE_USER_MUTATION, variables: { username: "username"} }
   */
  getGraphqlMutationObject(request, variables) {
    return { mutation: request, variables: variables }
  },
  /**
   * Subscription request object builder
   * @param {*} request
   * @param {*} variables variables JSON object used for the request
   * @returns {*} the subscription request object.
   */
  getGraphqlSubscriptionObject(request, variables) {
    return { subscription: request, variables: variables }
  },
  /**
   *
   * @param {*} variableNames The array of variable names needed for the request (e.g. query or mutation).
   * Are equal to the key elements of the variables {} within the request.
   * @param {*} variableParameters The array of variables passed.
   * Are equal to the value elements of the variables {} within the request.
   * @returns {*} the variables JSON object for the request object.
   * For example: variableNames = UPDATE_USER_VARIABLES (equal to ["email", "family_name", "given_name", "group",
   *     "phone_number", "username"]), variableParameters = [email, family_name, given_name, group, phone_number,
   *     username] will be built to:
   * {
   *  "email": email, "family_name": family_name, "given_name": given_name,
   *  "group":group, "phone_number": phone_number, "username": username
   * }
   */
  getVariablesParametersObject(variableNames, variableParameters) {
    let variables = {}
    if (variableNames.length === variableParameters.length) {
      for (let index = 0; index < variableNames.length; index++) {
        variables[variableNames[index]] = variableParameters[index]
      }
    }
    return variables
  },

  /** REQUEST CONSTRUCTORS INPUTS */

  /**
   * Query for retrieving the list of all alerts (unread).
   * @returns {*} Query request object.
   */
  getAlertList() {
    return ALERT_LIST_QUERY
  },
  /**
   * Query for retrieving the list of all alerts (refactored alerts implementation).
   * @returns {*} Query request object.
   */
  getAlertsQuery() {
    return GET_ALERTS_QUERY
  },
  /**
   * Query for retrieving the list of all archived alerts.
   * @param username Username
   * @returns {*} Query request object.
   */
  getArchivedAlertsQuery(username) {
    if (!username) {
      return GET_ARCHIVED_ALERTS_QUERY
    }
    let variableParameters = [username]
    return this.getGraphqlQueryObject(
      GET_ARCHIVED_ALERTS_QUERY,
      this.getVariablesParametersObject(
        GET_USER_STORAGE_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for retrieving the list of all archived alerts.
   * @returns {*} Query request object.
   * @param content Text content that you want to translate
   * @param source_language The language of the content
   * @param target_language The language to which you want to translate
   */
  getTranslationQuery(content, source_language, target_language) {
    if (!(content && source_language && target_language)) {
      return GET_TRANSLATION_QUERY
    }
    let variableParameters = [content, source_language, target_language]
    return this.getGraphqlQueryObject(
      GET_TRANSLATION_QUERY,
      this.getVariablesParametersObject(
        GET_TRANSLATION_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for retrieving the list of all archived alerts.
   * @returns {*} Query request object.
   */
  getUserStorageQuery() {
    return GET_USER_STORAGE_QUERY
  },
  /**
   * Query for retrieving the alert state containing the state and the amount of alerts.
   * @returns {*} Query request object.
   */
  getAlertState() {
    return ALERT_STATE_QUERY
  },
  /**
   * Query for retrieving the list of all user groups.
   * @returns {*} Query request object.
   */
  getListUserGroups() {
    return LIST_USER_GROUPS_QUERY
  },
  /**
   * Query for retrieving the list of all users in the database.
   * @returns {*} Query request object.
   */
  getUserList() {
    return USER_LIST_QUERY
  },
  /**
   * Query for retrieving the user licence info.
   * @returns {*} Query request object.
   */
  getUserLicenceInfo() {
    return GET_USER_LICENCE_INFO
  },
  /**
   * Mutation for deleting an user.
   * @param {*} username
   * @param groups The groups a user belongs to.
   * @returns {*} Mutation request object for the passed parameter variables. If no variable is provided, the
   *     mutation request object only includes the graphql mutation without variables
   */
  deleteUser(username, groups) {
    if (!username && !groups) {
      return DELETE_USER_MUTATION
    }
    let variableParameters = [username, groups]
    return this.getGraphqlMutationObject(
      DELETE_USER_MUTATION,
      this.getVariablesParametersObject(
        DELETE_USER_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Creates a reaction for a certain alert.
   * @param id
   * @param date
   * @param reaction
   * @param user
   * @return {any}
   */
  createReaction(id, date, reaction, user) {
    if (!id && !date && !reaction && !user) {
      return CREATE_REACTION_MUTATION
    }
    let variableParameters = [id, date, reaction, user]
    return this.getGraphqlMutationObject(
      CREATE_REACTION_MUTATION,
      this.getVariablesParametersObject(
        CREATE_REACTION_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Creates a comment for a certain alert.
   * @param id Alert id
   * @param date Current time
   * @param message Message (The actual written comment)
   * @param user The user that has written the comment.
   * @return {any} Graphql Mutation object.
   */
  createComment(id, date, message, user) {
    if (!id && !date && !message && !user) {
      return CREATE_COMMENT_MUTATION
    }
    let variableParameters = [id, date, message, user]
    return this.getGraphqlMutationObject(
      CREATE_COMMENT_MUTATION,
      this.getVariablesParametersObject(
        CREATE_COMMENT_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Moves alert to archive.
   * @param alert_id Alert id
   * @param alert_date Alert time
   * @return {any} Graphql Mutation object.
   */
  archiveAlert(alert_date, alert_id) {
    if (!alert_id && !alert_date) {
      return ARCHIVE_ALERT_MUTATION
    }
    let variableParameters = [alert_id, alert_date]
    return this.getGraphqlMutationObject(
      ARCHIVE_ALERT_MUTATION,
      this.getVariablesParametersObject(
        ARCHIVE_ALERT_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Updates the user's session object in the database.
   * @param username The user's name for whom the session should be saved.
   * @param field The field from the session object that should be updated.
   * @param stringValue The value that is used for updating the field.
   * @return {any} Graphql Mutation object.
   */
  updateUserSession(username, field, stringValue) {
    if (!username && !field && !stringValue) {
      return UPDATE_USER_SESSION_MUTATION
    }
    let variableParameters = [username, field, stringValue]
    return this.getGraphqlMutationObject(
      UPDATE_USER_SESSION_MUTATION,
      this.getVariablesParametersObject(
        UPDATE_USER_SESSION_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Updates the user's settings object in the database.
   * @param username The user's name for whom the settings should be saved.
   * @param language The field from the settings object that should be updated.
   * @return {any} Graphql Mutation object.
   */
  updateUserSettings(username, language) {
    if (!username && !language) {
      return UPDATE_USER_SETTINGS_MUTATION
    }
    let variableParameters = [username, language]
    return this.getGraphqlMutationObject(
      UPDATE_USER_SETTINGS_MUTATION,
      this.getVariablesParametersObject(
        UPDATE_USER_SETTINGS_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Returns subscription object. Will update when new reactions have been created.
   * @return {any} Subscription object.
   */
  createdReactionSubscription() {
    return CREATED_REACTION_SUBSCRIPTION
  },
  /**
   * Returns subscription object. Will update when new comments have been created.
   * @return {any} Subscription object.
   */
  createdCommentSubscription() {
    return CREATED_COMMENT_SUBSCRIPTION
  },
  updatedUserSettingsSubscription(username) {
    if (!username) {
      return UPDATED_USER_SETTINGS_SUBSCRIPTION
    }
    let variableParameters = [username]
    return this.getGraphqlSubscriptionObject(
      UPDATED_USER_SETTINGS_SUBSCRIPTION,
      this.getVariablesParametersObject(
        UPDATED_USER_SETTINGS_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Returns subscription object. Will update when new alerts have been created.
   * @return {any} Subscription object.
   */
  createdAlertSubscription() {
    return CREATED_ALERT_SUBSCRIPTION
  },
  /**
   * Returns subscription object. Will update when new archived alerts have been created.
   * @return {any} Subscription object.
   */
  createdArchivedAlertSubscription() {
    return CREATED_ARCHIVED_ALERT_SUBSCRIPTION
  },
  /**
   * Query for retrieving the user information for a specific user.
   * @param {*} username Username
   * @returns {*} Query request object for the passed parameter variables. If no variable is provided, the query
   *     request object only includes the graphql query without variables
   */
  getUserInformation(username) {
    if (!username) {
      return USER_INFORMATION_QUERY
    }
    let variableParameters = [username]
    return this.getGraphqlQueryObject(
      USER_INFORMATION_QUERY,
      this.getVariablesParametersObject(
        USER_INFORMATION_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Mutation for adding a new user to the cognito user pool.
   * @param {*} email New Email address for the user
   * @param {*} family_name New Family name
   * @param {*} given_name New First name
   * @param {*} groups New User group
   * @param {*} phone_number New Phone number
   * @param {*} username New Username
   * @param removedGroups Probably the groups that should get removed from a user.
   * @returns {*} Mutation request object for the passed parameter variables. If no variable is provided, the
   *     mutation request object only includes the graphql mutation without variables
   */
  updateUser(
    email,
    family_name,
    given_name,
    groups,
    phone_number,
    username,
    removedGroups
  ) {
    if (
      !email &&
      !family_name &&
      !given_name &&
      !groups &&
      !phone_number &&
      !username &&
      !removedGroups
    ) {
      return UPDATE_USER_MUTATION
    }
    let variableParameters = [
      email,
      family_name,
      given_name,
      groups,
      phone_number,
      username,
      removedGroups
    ]
    return this.getGraphqlMutationObject(
      UPDATE_USER_MUTATION,
      this.getVariablesParametersObject(
        UPDATE_USER_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Mutation for adding a new user to the cognito user pool.
   * @param {*} email Email address for the new user
   * @param {*} family_name Family name
   * @param {*} given_name First name
   * @param {*} groups User groups
   * @param {*} phone_number Phone number
   * @param {*} username Username
   * @returns {*} Mutation request object for the passed parameter variables. If no variable is provided, the
   *     mutation request object only includes the graphql mutation without variables
   */
  insertNewUser(
    email,
    family_name,
    given_name,
    groups,
    phone_number,
    username
  ) {
    if (
      !email &&
      !family_name &&
      !given_name &&
      !groups &&
      !phone_number &&
      !username
    ) {
      return INSERT_NEW_USER_MUTATION
    }
    let variableParameters = [
      email,
      family_name,
      given_name,
      groups,
      phone_number,
      username
    ]
    return this.getGraphqlMutationObject(
      INSERT_NEW_USER_MUTATION,
      this.getVariablesParametersObject(
        INSERT_NEW_USER_VARIABLES,
        variableParameters
      )
    )
  },
  manageUser(user, action) {
    let variableParameters = [user, action]
    return this.getGraphqlMutationObject(
      MANAGE_USER_MUTATION,
      this.getVariablesParametersObject(
        MANAGE_USER_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for retrieving the list of all deployed services (e.g. CSLegal or CSQuantengine).
   * @returns {*} Query request object.
   */
  getDeployedServices() {
    return DEPLOYED_SERVICES_QUERY
  },
  /**
   * Query for retrieving the list of all groups in the database.
   * @returns {*} Query request object.
   */
  getGroups() {
    return GROUP_LIST_QUERY
  },
  /**
   * Query for retrieving the list of all roles in the database.
   * @returns {*} Query request object.
   */
  getRoles() {
    return ROLE_LIST_QUERY
  },
  /**
   * Query for retrieving the permissions for the specified role.
   * @param {*} role Role
   * @returns {*} Query request object for the passed parameter variables. If no variable is provided, the query
   *     request object only includes the graphql query without variables
   */
  getPermissionsForRole(role) {
    if (!role) {
      return PERMISSIONS_FOR_ROLE
    }
    let variableParameters = [role]
    return this.getGraphqlQueryObject(
      PERMISSIONS_FOR_ROLE,
      this.getVariablesParametersObject(
        PERMISSIONS_FOR_ROLE_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for retrieving the list of all possible permissions from the database.
   * @returns {*} Query request object.
   */
  getPermissionStructure() {
    return PERMISSION_STRUCTURE_QUERY
  },
  /**
   * Query for retrieving the list of permission-related descriptors from the database.
   * @returns {*} Query request object.
   */
  getPermissionDescriptors() {
    return PERMISSION_DESCRIPTORS_QUERY
  },
  /**
   * Query for retrieving the list of all users in the database.
   * @returns {*} Query request object.
   */
  getUsers() {
    return GET_USERS_QUERY
  },
  /**
   * Query for retrieving the list of all group names searched by an id in the database.
   * @param groups a list of all requested groups ids
   * @returns {*} Query request object.
   */
  getGroupNamesByIds(groups) {
    let variableParameters = [groups]
    return this.getGraphqlQueryObject(
      GROUPNAMES_BYIDS_LIST_QUERY,
      this.getVariablesParametersObject(
        GROUPNAMES_BYIDS_LIST_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for update a group in the database.
   * @param SK the key of a group
   * @param name a String for updating the name of a group
   * @param Role a list of the new roles for updating the roles of a group
   * @param RemovedRoles a list of all old roles which are stored before in a group
   * @returns {*} Query request object.
   */
  updateGroup(SK, name, Role, RemovedRoles) {
    if (!Role && !SK && !name && !RemovedRoles) {
      return UPDATE_GROUP_MUTATION
    }
    let variableParameters = [Role, SK, name, RemovedRoles]
    return this.getGraphqlMutationObject(
      UPDATE_GROUP_MUTATION,
      this.getVariablesParametersObject(
        UPDATE_GROUP_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for update a role in the database.
   * @param SK the key of a role
   * @param name a String for updating the name of a role
   * @returns {*} Query request object.
   */
  updateRole(SK, name) {
    if (!SK && !name) {
      return UPDATE_ROLE_MUTATION
    }
    let variableParameters = [SK, name]
    return this.getGraphqlMutationObject(
      UPDATE_ROLE_MUTATION,
      this.getVariablesParametersObject(
        UPDATE_ROLE_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for create a new role in the database.
   * @param SK the key of a role
   * @returns {*} Query request object.
   */
  createRole(SK) {
    if (!SK) {
      return INSERT_NEW_ROLE_MUTATION
    }
    let variableParameters = [SK]
    return this.getGraphqlMutationObject(
      INSERT_NEW_ROLE_MUTATION,
      this.getVariablesParametersObject(
        INSERT_NEW_ROLE_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for create a new group in the database
   * @param SK the key of a group
   * @param Roles a list of roles
   * @returns {*} Query request object.
   */
  createGroup(SK, Roles) {
    if (!SK) {
      return INSERT_NEW_GROUP_MUTATION
    }
    let variableParameters = [SK, Roles]
    return this.getGraphqlMutationObject(
      INSERT_NEW_GROUP_MUTATION,
      this.getVariablesParametersObject(
        INSERT_NEW_GROUP_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for delete a group in the database.
   * @param SK the key of a group
   * @param Roles a list of roles
   * @{*} Query request object.
   */
  deleteGroup(SK, Roles) {
    if (!SK && !Roles) {
      return DELETE_GROUP_MUTATION
    }
    let variableParameters = [SK, Roles]
    return this.getGraphqlMutationObject(
      DELETE_GROUP_MUTATION,
      this.getVariablesParametersObject(
        DELETE_GROUP_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Query for delete a role in the database
   * @param SK the key of a role
   * @returns {*} Query request object.
   */
  deleteRole(SK) {
    if (!SK) {
      return DELETE_ROLE_MUTATION
    }
    let variableParameters = [SK]
    return this.getGraphqlMutationObject(
      DELETE_ROLE_MUTATION,
      this.getVariablesParametersObject(
        DELETE_ROLE_VARIABLES,
        variableParameters
      )
    )
  },
  /**
   * Mutation for marking an alert as read (will be deleted and not shown when retrieving the alertList).
   * @param {*} alertId Alert ID
   * @param {*} alertType Alert Type (e.g. "WARNING" or "ERROR")
   * @returns {*} Mutation request object for the passed variables. If no variable is provided, the mutation request
   *     object only includes the graphql mutation without variables
   */
  markAlertAsRead(alertId, alertType) {
    if (!alertId && !alertType) {
      return MARK_ALERT_AS_READ_MUTATION
    }
    let variableParameters = [alertId, alertType]
    /* readAlert is the key for the variables alertId and alertType */
    let variables = {
      readAlert: this.getVariablesParametersObject(
        MARK_ALERT_AS_READ_VARIABLES,
        variableParameters
      )
    }
    return this.getGraphqlMutationObject(MARK_ALERT_AS_READ_MUTATION, variables)
  },

  /**
   * Get all permissions that a specific user has.
   * @param groups A list of groups the user belong to.
   */
  getUserPermissionsQuery(groups) {
    return this.getGraphqlQueryObject(
      GET_ALL_USER_PERMISSIONS,
      this.getVariablesParametersObject(GET_ALL_USER_PERMISSIONS_VARIABLES, [
        groups
      ]),
      null
    )
  },
  /**
   * Adds a custom fetch policy to the provided GraphQL query
   * @param query The GraphQL query to change
   * @param cachePolicy Custom fetch policy to add to the query
   */
  addFetchPolicy(query, cachePolicy) {
    query['fetchPolicy'] = cachePolicy
    return query
  },
  /**
   * Mutation for adding, updating and deleting permissions.
   * @param {*} upsertPermissions Permissions to be added or updated
   * @param {*} deletePermissions Permissions to be deleted
   * @returns {*} Mutation request object for the passed variables. If no variable is provided, the mutation request
   *     object only includes the graphql mutation without variables
   */
  updatePermissions(upsertPermissions, deletePermissions) {
    if (!upsertPermissions && !deletePermissions) {
      return UPDATE_PERMISSIONS_MUTATION
    }

    return this.getGraphqlMutationObject(
      UPDATE_PERMISSIONS_MUTATION,
      this.getVariablesParametersObject(UPDATE_PERMISSIONS_VARIABLES, [
        deletePermissions,
        upsertPermissions
      ])
    )
  }
}
