import gql from 'graphql-tag'

export const ALERT_STATE_QUERY = gql`
  query getAlertState {
    getAlertState {
      alertCount
      alertState
    }
  }
`
