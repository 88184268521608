import gql from 'graphql-tag'

export const GET_USER_LICENCE_INFO = gql`
  query getUserLicenceInfo {
    getUserLicenceInfo {
      usedLicences
      totalLicences
    }
  }
`
