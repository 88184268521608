import { render, staticRenderFns } from "./index.vue?vue&type=template&id=bbdf8daa&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=bbdf8daa&prod&lang=scss&scoped=true"
import style1 from "./index.vue?vue&type=style&index=1&id=bbdf8daa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbdf8daa",
  null
  
)

/* custom blocks */
import block0 from "./lang.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Frunner%2Fwork%2Fcsbaseapp-frontend%2Fcsbaseapp-frontend%2Fsrc%2Fviews%2Fcsbase-notification-history%2Findex.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports