<i18n src="./lang.json"></i18n>
<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar
        class="csGradientBlueBackground fullscreenDialogToolbar mb-3 pa-0"
        flat
      >
        <v-toolbar-title class="mt-2 pa-0 fullscreenDialogToolbarTitle">
          <v-list-item-avatar class="supportAvatar" color="white" size="60px">
            <div id="supportImage" />
          </v-list-item-avatar>
          <span style="color: white">{{ $t('support') }}</span>
          <v-spacer />
          <cs-action-button
            :action="onCloseSupportComponent"
            class="mr-1"
            :icon="$csBasicsIcons.closeFontawesome"
            icon-color="white"
            icon-size="22"
            :tooltip="$t('close')"
          />
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pl-2">
      <v-list two-line>
        <v-list-item-title
          class="pl-4 pb-4 mt-2"
          style="
            padding-bottom: 4px !important;
            font-size: 20px !important;
            color: var(--cs-dark);
          "
        >
          {{ $t('contactDetails') }}
        </v-list-item-title>
        <v-list-item-content class="pl-4 pb-4 mt-2">
          <div
            class="pb-3"
            style="display: flex; align-items: center; width: 100%"
          >
            <div class="csSubHeading" style="width: 30%">{{ $t('phone') }}</div>
            <div class="csText" style="width: 70%; margin-top: 2px">
              {{ phoneNumber }}
            </div>
          </div>
          <div
            class="pb-3"
            style="display: flex; align-items: center; width: 100%"
          >
            <div class="csSubHeading" style="width: 30%">{{ $t('email') }}</div>
            <div
              class="csText"
              style="
                display: flex;
                flex-direction: column;
                width: 70%;
                margin-top: 2px;
              "
            >
              <cs-tooltip top>
                <template #tooltipActivator>
                  <a
                    href="mailto:support@content-software.com?subject=Supportrequest%20CSQuantengine"
                    style="color: var(--cs-primary-dark-blue)"
                  >
                    {{ csMailAddress }}
                  </a>
                </template>
                <template #tooltipContent>
                  <span>{{ $t('sendMailTooltip') }} </span>
                </template>
              </cs-tooltip>
            </div>
          </div>
        </v-list-item-content>
      </v-list>
      <div
        style="
          margin-right: auto;
          min-width: 90%;
          max-width: 95%;
          margin-left: auto;
        "
      >
        <v-divider
          style="
            background: #afafaf;
            border: none;
            border-top: 2px solid #afafaf;
          "
        />
      </div>
      <v-list>
        <v-list-item-title
          class="pl-4 pb-4 mt-2"
          style="
            padding-bottom: 4px !important;
            font-size: 20px !important;
            color: var(--cs-dark);
          "
        >
          {{ $t('furtherContactDetails') }}
        </v-list-item-title>

        <v-list-item-content class="pl-4 pb-4 mt-2">
          <div
            class="pb-3"
            style="display: flex; align-items: center; width: 100%"
          >
            <div class="csSubHeading" style="width: 30%">{{ $t('fax') }}</div>
            <div class="csText" style="width: 70%; margin-top: 2px">
              {{ csFaxNumber }}
            </div>
          </div>
          <div class="pb-3" style="display: flex; width: 100%">
            <div class="csSubHeading" style="width: 30%">
              {{ $t('address') }}
            </div>
            <div
              class="csText"
              style="
                display: flex;
                flex-direction: column;
                width: 70%;
                margin-top: 2px;
              "
            >
              <span class="mb-1">{{ csAddress.street }},</span>
              <span class="mb-1"
                >{{ csAddress.postCode }} {{ csAddress.city }}
              </span>
              <span>{{ csAddress.country }}</span>
            </div>
          </div>
        </v-list-item-content>
      </v-list>
      <div
        style="
          margin-right: auto;
          min-width: 90%;
          max-width: 95%;
          margin-left: auto;
        "
      >
        <v-divider
          style="
            background: #afafaf;
            border: none;
            border-top: 2px solid #afafaf;
          "
        />
      </div>
      <v-list>
        <v-list-item-title
          class="pl-4 pb-4 mt-2"
          style="
            padding-bottom: 4px !important;
            font-size: 20px !important;
            color: var(--cs-dark);
          "
        >
          {{ $t('availabilityTimes') }}
        </v-list-item-title>
        <v-list-item-content class="pl-4 pb-4 mt-2">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="pb-3"
            style="display: flex; align-items: center; width: 100%"
          >
            <div class="csSubHeading" style="width: 30%">
              {{ $t(item.day) }}
            </div>
            <div class="csText" style="width: 70%; margin-top: 2px">
              {{ index > 4 ? $t('noPersonalSupport') : $t('availabilityTime') }}
            </div>
          </div>
        </v-list-item-content>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CsbaseSupport',
  data: () => ({
    items: [
      { day: 'monday' },
      { day: 'tuesday' },
      { day: 'wednesday' },
      { day: 'thursday' },
      { day: 'friday' },
      { day: 'saturday' },
      { day: 'sunday' }
    ],
    supportOverlayMenu: false,
    csMailAddress: 'support@content-software.com',
    csAddress: {
      street: 'Siemensstraße 6',
      postCode: '61352',
      city: 'Bad Homburg',
      country: 'Germany'
    },
    csFaxNumber: '+49 6172 662 621 - 99',
    phoneNumber: '+49 6172 662 621 - 77'
  }),
  methods: {
    /* Sends event message to parent component to close wrapping dialog */
    onCloseSupportComponent() {
      this.$emit('supportComponentClosed')
    }
  }
}
</script>

<style lang="scss" scoped>
#supportImage {
  height: 50px;
  width: 50px;
  background: url('../../assets/images/CS_Icon_cmyk_250_250.png') no-repeat;
  background-size: contain;
}

.fullscreenDialogToolbar {
  padding-top: 7.5%;
  padding-bottom: 5%;
  position: sticky !important;
  top: 0;
  z-index: 999;
  height: 100px !important;

  .fullscreenDialogToolbarTitle {
    color: $cs-dark;
    padding-top: 40px;
    font-weight: normal;
    font-size: 24px;
    width: 100%;
    display: flex;
    align-items: center;

    .supportAvatar i {
      font-size: 5rem;
      font-style: normal;
      background: rgba(0, 0, 0, 0)
        linear-gradient(to left bottom, rgb(3, 152, 226), rgb(2, 67, 100))
        repeat scroll 0 0;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-animation: fontgradient 2s ease infinite;
      -moz-animation: fontgradient 2s ease infinite;
    }
  }
}
</style>
