import gql from 'graphql-tag'

export const USER_LIST_QUERY = gql`
  query getUserList {
    getUserList {
      userlist {
        account_status
        family_name
        given_name
        username
      }
    }
  }
`
