var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"homeScreenWrapper"},[_c('div',{staticClass:"welcomeMessageWrapper"},[_c('h1',{staticClass:"welcomeMessage"},[_vm._v(_vm._s(_vm.greeting))]),_c('div',{staticClass:"welcomeMessageDivider"})]),_c('div',{staticClass:"chooseServiceTextWrapper"},[_c('h3',{staticClass:"chooseServiceText"},[_vm._v(_vm._s(_vm.chooseServiceText))])]),_c('div',{staticClass:"serviceNavigation"},_vm._l((_vm.services),function(service){return _c('div',{key:service.rootServiceName,staticClass:"serviceNavigationItemWrapper"},[_c('div',{staticClass:"serviceNavigationItemShadow"},[_c('div',{staticClass:"serviceNavigationItemBorder"},[_c('div',{staticClass:"serviceNavigationItem",style:([
              service.addPadding ? { 'padding-left': '18px !important' } : {},
              _vm.rootServiceName === service.rootServiceName
                ? {
                    background: '#34beed',
                    top: '3px',
                    left: '3px',
                    height: '104px',
                    width: '89px'
                  }
                : {},
              _vm.hoveredSubserviceApplication === service.rootServiceName
                ? { background: '#34beed' }
                : {}
            ]),on:{"click":function($event){return _vm.selectService(service)},"mouseleave":function($event){_vm.hoveredSubserviceApplication = ''},"mouseover":function($event){_vm.hoveredSubserviceApplication = service.rootServiceName}}},[_c('v-icon',{staticClass:"serviceNavigationItemIcon",style:([
                _vm.rootServiceName === service.rootServiceName
                  ? { color: '#F8F8F8 !important', transition: '0.5s' }
                  : {},
                _vm.hoveredSubserviceApplication === service.rootServiceName
                  ? { color: '#F8F8F8 !important', transition: '0.5s' }
                  : {}
              ])},[_vm._v(" "+_vm._s(service.icon)+" ")])],1)])])])}),0),_c('div',{staticClass:"hexGridWrapper"},[_c('div',{staticClass:"hexGridContainer"},[_c('div',{staticClass:"hexGrid"},[_c('div',{staticClass:"serviceNavigationItemShadow"},_vm._l((_vm.subservices.filter(
              function (item) { return item.enabled !== false; }
            )),function(subservice){return _c('div',{key:subservice.routerLinkName + '_' + subservice.enabled,staticClass:"hexWrapper"},[_c('div',{staticClass:"hex",style:(_vm.rootServiceName.toUpperCase() !==
                  subservice.application.toUpperCase() &&
                _vm.rootServiceName.toUpperCase() !== ''
                  ? {
                      background: 'white',
                      transition: '0.5s',
                      opacity: '0.4'
                    }
                  : {}),on:{"mouseleave":function($event){;(_vm.hoveredSubservice = ''), (_vm.hoveredSubserviceApplication = '')},"mouseover":function($event){;(_vm.hoveredSubservice = subservice.routerLinkName),
                  (_vm.hoveredSubserviceApplication = subservice.application)}}},[_c('router-link',{attrs:{"to":{ name: subservice.routerLinkName }}},[_c('div',{staticClass:"hexContent"},[_c('v-icon',{staticClass:"serviceNavigationItemIcon",style:(_vm.rootServiceName.toUpperCase() !==
                        subservice.application.toUpperCase() &&
                      _vm.rootServiceName.toUpperCase() !== ''
                        ? { color: '#78909C ', transition: '0.5s' }
                        : {})},[_vm._v(" "+_vm._s(subservice.icon)+" ")])],1)])],1)])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }