import gql from 'graphql-tag'

export const UPDATE_PERMISSIONS_MUTATION = gql`
  mutation updatePermissions(
    $deletePermissions: [PermissionsEntryInput]!
    $upsertPermissions: [PermissionsEntryInput]!
  ) {
    updatePermissions(
      deletePermissions: $deletePermissions
      upsertPermissions: $upsertPermissions
    )
  }
`
export const UPDATE_PERMISSIONS_VARIABLES = [
  'deletePermissions',
  'upsertPermissions'
]
