import Usermanagement from '../views/csbase-user-management'
import Groupmanagement from '../views/csbase-group-management'
import Rolemanagement from '../views/csbase-role-management'
import Home from '../views/csbase-home'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { BASE_CLIENT } from '../main'
import GraphqlHelper from '../js/graphqlHelper'
import store from '../store/index'
import CSBasicsI18n from '@content-software/cs-frontend-components/src/cs-basics-i18n'
import permissionHelper from '../js/permissions/permissionHelper'
import CSLEGAL_NAVIGATION_ELEMENTS from '@content-software/cslegal-frontend/src/js/navigationElements'

Vue.use(VueRouter)

// Permissions restriction => route 'name' or route 'meta.parent' are used for user access restrictions, do not change
// without changing in the Permissions table

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { showBreadCrumbs: false }
  },
  {
    path: '/administration/usermanagement',
    name: 'Usermanagement',
    component: Usermanagement,
    // root must match the navigation element routerlinkName
    meta: { serviceKey: 'Administration' }
  },
  {
    path: '/administration/groupmanagement',
    name: 'Groupmanagement',
    component: Groupmanagement,
    // root must match the navigation element routerlinkName
    meta: { serviceKey: 'Administration' }
  },
  {
    path: '/administration/rolemanagement',
    name: 'Rolemanagement',
    component: Rolemanagement,
    // root must match the navigation element routerlinkName
    meta: { serviceKey: 'Administration' }
  },
  {
    path: '*',
    redirect: '/404-page'
  },
  {
    path: '/404-page',
    name: 'Page404',
    component: () => import('../views/404-page/index'),
    meta: { showBreadCrumbs: false, requiresAuth: true }
  }
]

const router = new VueRouter({ routes })

const i18n = new CSBasicsI18n(require('../js/lang.json'), store)

router.beforeEach(async (to, from, next) => {
  let isLoggedIn = await store.dispatch('csbaseAuthModule/getAuthenticatedUser')
  /**
   * Checks if a route is invalid
   * @return {boolean}
   */
  const isInvalidRoute = function () {
    const domain = window.location.origin
    const href = window.location.href
    const hash = window.location.hash
    const hrefWithoutDomain = href.split(domain)
    const hrefWithoutDomain_WithoutHash = hrefWithoutDomain[1].split(hash)[0]
    let invalidRoute = false
    if (hash !== '#/') {
      // If the URL path after the domain starts with a character other than '#'
      const routeStartsWithInvalidCharacter =
        hrefWithoutDomain_WithoutHash !== '/'
      // If the end of the URL ends with '#'. These characters are somehow ignored by the router.
      const routeEndsWithInvalidCharacter =
        hash.endsWith('#') || hash.endsWith('/')
      invalidRoute =
        routeStartsWithInvalidCharacter || routeEndsWithInvalidCharacter
    }
    return invalidRoute
  }

  /**
   * Handles navigation for invalid routes.
   */
  const handleInvalidRoute = function () {
    const domain = window.location.origin
    const link404 = domain + '/#/404-page'
    window.open(link404, '_self')
  }

  let serviceKey = to.meta.serviceKey
  // Navigate directly without check if route has no serviceKey
  if (typeof serviceKey === 'undefined') {
    if (isInvalidRoute()) {
      handleInvalidRoute()
    } else {
      if (to.meta && to.meta.requiresAuth && !isLoggedIn) {
        next('/home')
      } else {
        next()
      }
    }
    return
  }
  let subServiceKey = to.meta.parent
  if (typeof subServiceKey === 'undefined') {
    subServiceKey = to.name
  }
  let serviceNotDeployed = {
    props: {
      notificationTitle: i18n.t('serviceNotDeployedTitle', { app: serviceKey }),
      notificationMessage: i18n.t('serviceNotDeployedText')
    },
    options: {}
  }
  let noAccessPermission = {
    props: {
      notificationTitle: i18n.t('noAccessPermissionTitle', { app: serviceKey }),
      notificationMessage: i18n.t('noAccessPermissionText')
    },
    options: {}
  }

  let subNavigationElementName =
    serviceKey === 'CSLegal'
      ? CSLEGAL_NAVIGATION_ELEMENTS[0].subNavigationElements.find(
          (subNavigationElement) =>
            subNavigationElement.routerLinkName === subServiceKey
        ).subServiceRightsName
      : subServiceKey
  let checkHasPermission = permissionHelper.hasSubServiceAccess(
    serviceKey,
    subNavigationElementName,
    null,
    null
  )

  let hasPermission = false
  // Needed to evaluate the permission correctly.
  await checkHasPermission
    .then((response) => {
      hasPermission = response
    })
    .catch((err) => {
      Vue.prototype.$console.error(err)
    })
  if (serviceKey === 'Administration') {
    if (hasPermission) {
      if (isInvalidRoute()) {
        handleInvalidRoute()
      } else {
        if (to.meta && to.meta.requiresAuth && !isLoggedIn) {
          next('/home')
        } else {
          next()
        }
      }
    } else {
      Vue.prototype.$csNotification(
        noAccessPermission.props,
        noAccessPermission.options
      )
      next(false)
    }
    return
  }
  if (serviceKey === 'CSResearchHosting') {
    if (hasPermission) {
      if (isInvalidRoute()) {
        handleInvalidRoute()
      } else {
        if (to.meta && to.meta.requiresAuth && !isLoggedIn) {
          next('/home')
        } else {
          next()
        }
      }
    } else {
      Vue.prototype.$csNotification(
        noAccessPermission.props,
        noAccessPermission.options
      )
      next(false)
    }
    return
  }
  // Check for necessary permissions
  const permissionCheck = (deployedServices) => {
    let serviceDeployed = deployedServices.find((serviceName) => {
      //TODO: Adjust ServiceCatalog in every environment
      if (serviceKey === 'CSQuantengine') {
        return serviceName.toLocaleLowerCase() === 'csquantengine'
      }
      return serviceName.toLocaleLowerCase() === serviceKey.toLocaleLowerCase()
    })
    if (serviceDeployed) {
      if (hasPermission) {
        if (isInvalidRoute()) {
          handleInvalidRoute()
        } else {
          if (to.meta && to.meta.requiresAuth && !isLoggedIn) {
            next('/home')
          } else {
            next()
          }
        }
      } else {
        Vue.prototype.$csNotification(
          noAccessPermission.props,
          noAccessPermission.options
        )
        next(false)
      }
    } else {
      Vue.prototype.$csNotification(
        serviceNotDeployed.props,
        serviceNotDeployed.options
      )
      next('/')
    }
  }
  let deployedServiceStoreObj =
    store.getters['csbaseAppIntegrationModule/deployedServices']
  /* Check for deployed services from store if already initialized.
       When the object is not null it is a JSON object and if that object has content inside, it has been filled with
        deployedServices already */
  if (
    deployedServiceStoreObj !== null &&
    Object.keys(deployedServiceStoreObj).length > 0
  ) {
    permissionCheck(
      Object.keys(store.getters['csbaseAppIntegrationModule/deployedServices'])
    )
  } else {
    if (isLoggedIn) {
      // Check for deployed services from store via graphql call
      await BASE_CLIENT.query({ query: GraphqlHelper.getDeployedServices() })
        .then((response) => {
          let deployedServices = []
          response.data.getDeployedServices.forEach((deployedService) => {
            deployedServices.push(deployedService.service)
          })
          if (deployedServices && deployedServices.length > 0) {
            permissionCheck(deployedServices)
          } else {
            next('/')
          }
        })
        .catch(() => {
          next('/')
        }) // If error occurs during api call, navigate to root route
    } else {
      next('/')
    }
  }
})

export default router
