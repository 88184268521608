import gql from 'graphql-tag'

export const ROLE_LIST_QUERY = gql`
  query getRoles {
    getRoles {
      SK
      name
      Groups
    }
  }
`
