import gql from 'graphql-tag'

export const PERMISSION_STRUCTURE_QUERY = gql`
  query getPermissionStructure {
    getPermissionStructure {
      parent
      permission
      resourceId
      resourceType
    }
  }
`
