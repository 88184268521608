import gql from 'graphql-tag'

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $email: String!
    $family_name: String!
    $given_name: String!
    $groups: [String!]!
    $phone_number: String
    $username: String!
    $removedGroups: [String]!
  ) {
    updateUser(
      user: {
        email: $email
        family_name: $family_name
        given_name: $given_name
        groups: $groups
        phone_number: $phone_number
        username: $username
        removedGroups: $removedGroups
      }
    ) {
      account_status
      created
      email
      family_name
      given_name
      groups
      last_modified
      phone_number
      username
    }
  }
`
export const UPDATE_USER_VARIABLES = [
  'email',
  'family_name',
  'given_name',
  'groups',
  'phone_number',
  'username',
  'removedGroups'
]
