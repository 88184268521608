var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editorPanel"},[_c('div',{staticClass:"editorPanelColumn1"},[(_vm.hasLicenceFooter)?_c('cs-widget-card',{attrs:{"has-context-menu":false,"has-footer":"","widget-card-text-wrapper-style":{ 'padding-top': '55px !important' },"widget-card-title":_vm.listTitle},scopedSlots:_vm._u([{key:"quickAction",fn:function(){return [(
            _vm.PermissionHelper.userHasExecutionPermission(
              _vm.permissionObject.edit,
              _vm.$store
            )
          )?_c('cs-action-button',{attrs:{"action":_vm.createNewItemObject,"icon":_vm.$csBasicsIcons.addFontawesome,"icon-size":"18","move-tooltip-by":"10","tooltip":_vm.$t('createElement')}}):_vm._e()]},proxy:true},{key:"widgetCardText",fn:function(){return [_c('cs-perfect-scrollbar',{staticStyle:{"margin-right":"-10px"},attrs:{"height":"73.25vh"},scopedSlots:_vm._u([{key:"perfectScrollbarContent",fn:function(){return [_c('v-list',[_c('v-list-item-group',_vm._l((_vm.itemList),function(item){return _c('v-list-item',{key:_vm.getIdentifier(item).idValue,staticClass:"mr-3",style:([
                    _vm.selectedItem &&
                    _vm.$csBasicsUtils.equals(
                      _vm.selectedItem[_vm.getIdentifier(item).idKey],
                      item[_vm.getIdentifier(item).idKey]
                    )
                      ? { background: _vm.$csBasicsColors.csBackground }
                      : {}
                  ]),on:{"click":function($event){return _vm.selectItem(item)}}},[_vm._t("listItem",[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.getLabel(item))+" ")])],1)],{"item":item}),(_vm.showContextMenu)?_c('cs-context-menu',{attrs:{"deleteable":_vm.PermissionHelper.userHasExecutionPermission(
                        _vm.permissionObject.delete,
                        _vm.$store
                      ),"left":"","tooltip":_vm.$t('contextMenu')},on:{"deleteItem":function($event){return _vm.deleteItem(item)}},scopedSlots:_vm._u([{key:"customContextMenuEntries",fn:function(){return [_vm._t("customItemMenuEntry",null,{"item":item}),_vm._t("customItemMenuEntry2",null,{"item":item})]},proxy:true}],null,true)}):_vm._e()],2)}),1)],1)]},proxy:true}],null,true)})]},proxy:true},{key:"widgetCardInfoFooterContent",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.infoFooterText))])]},proxy:true}],null,false,2331127481)}):_c('cs-widget-card',{attrs:{"has-context-menu":false,"widget-card-text-wrapper-style":{ 'padding-top': '55px !important' },"widget-card-title":_vm.listTitle},scopedSlots:_vm._u([{key:"quickAction",fn:function(){return [(
            _vm.PermissionHelper.userHasExecutionPermission(
              _vm.permissionObject.edit,
              _vm.$store
            )
          )?_c('cs-action-button',{attrs:{"action":_vm.createNewItemObject,"icon":_vm.$csBasicsIcons.addFontawesome,"icon-size":"18","move-tooltip-by":"10","tooltip":_vm.$t('createElement')}}):_vm._e()]},proxy:true},{key:"widgetCardText",fn:function(){return [_c('cs-perfect-scrollbar',{staticStyle:{"margin-right":"-10px"},attrs:{"height":"75vh"},scopedSlots:_vm._u([{key:"perfectScrollbarContent",fn:function(){return [_c('v-list',[_c('v-list-item-group',_vm._l((_vm.itemList),function(item){return _c('v-list-item',{key:_vm.getIdentifier(item).idValue,staticClass:"mr-3",style:([
                    _vm.selectedItem &&
                    _vm.$csBasicsUtils.equals(
                      _vm.selectedItem[_vm.getIdentifier(item).idKey],
                      item[_vm.getIdentifier(item).idKey]
                    )
                      ? { background: _vm.$csBasicsColors.csBackground }
                      : {}
                  ]),on:{"click":function($event){return _vm.selectItem(item)}}},[_vm._t("listItem",[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.getLabel(item)))])],1)],{"item":item}),(_vm.showContextMenu)?_c('cs-context-menu',{attrs:{"deleteable":_vm.PermissionHelper.userHasExecutionPermission(
                        _vm.permissionObject.delete,
                        _vm.$store
                      ),"left":"","tooltip":_vm.$t('contextMenu')},on:{"deleteItem":function($event){return _vm.deleteItem(item)}},scopedSlots:_vm._u([{key:"customContextMenuEntries",fn:function(){return [_vm._t("customItemMenuEntry",null,{"item":item}),_vm._t("customItemMenuEntry2",null,{"item":item})]},proxy:true}],null,true)}):_vm._e()],2)}),1)],1)]},proxy:true}],null,true)})]},proxy:true}])})],1),_c('div',{staticClass:"editorPanelColumn2"},[_c('cs-widget-card',{attrs:{"has-context-menu":false,"widget-card-text-wrapper-style":{ 'padding-top': '55px !important' },"widget-card-title":_vm.singleElementTitle},scopedSlots:_vm._u([{key:"quickAction",fn:function(){return [[_c('v-tooltip',{attrs:{"bottom":"","content-class":"csPrimary tooltip-bottom"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [(_vm.isReadOnly)?_c('v-icon',_vm._g({staticStyle:{"margin-right":"35%"},attrs:{"color":"white","size":"24"}},on),[_vm._v(_vm._s(_vm.$csBasicsIcons.readonly)+" ")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('userHasNoPermissionToEdit')))])]),_c('cs-action-button',{attrs:{"action":_vm.saveItem,"disabled":!_vm.saveButtonActive,"icon":_vm.$csBasicsIcons.save,"icon-size":"24","move-tooltip-by":"10","tooltip":_vm.$t('save')}})]]},proxy:true},{key:"widgetCardText",fn:function(){return [_c('cs-perfect-scrollbar',{attrs:{"height":"75vh"},scopedSlots:_vm._u([{key:"perfectScrollbarContent",fn:function(){return [_c('v-list',[(!_vm.selected)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"ml-2 mr-2"},[_c('v-icon',{attrs:{"color":_vm.$csBasicsColors.csPrimaryDarkBlue,"size":"26"}},[_vm._v(" "+_vm._s(_vm.$csBasicsIcons.informationNew)+" ")])],1),_c('v-list-item-content',{staticStyle:{"margin-top":"1px"}},[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.$t('noItemSelected')))])],1)],1):_c('v-form',{ref:"form",staticClass:"ml-5 mr-5",attrs:{"lazy-validation":"","readonly":_vm.isReadOnly},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._t("default",null,{"creationMode":_vm.itemCreationMode,"dirtyChanger":_vm.setDirty,"item":_vm.selectedItem})],2)],1)]},proxy:true}])})]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }