import gql from 'graphql-tag'

export const PERMISSIONS_FOR_ROLE = gql`
  query getPermissionsForRole($role: String!) {
    getPermissionsForRole(role: $role) {
      resourceType
      resourceId
      role
      permissions
    }
  }
`
export const PERMISSIONS_FOR_ROLE_VARIABLES = ['role']
