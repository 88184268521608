var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"csEditRoleComponent"}},[_c('v-overlay',{attrs:{"absolute":true,"opacity":0.07,"value":_vm.loading}},[_c('cs-progress-circular')],1),_c('v-list',[_c('v-text-field',{attrs:{"label":_vm.$t('name'),"rules":[function (v) { return !!v || _vm.$t('itemIsRequired'); }],"tabindex":"2"},on:{"input":function($event){return _vm.dirtyChanger(true)}},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),(_vm.containsAdminPermission)?_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{staticClass:"ma-0",attrs:{"color":_vm.$csBasicsColors.csPrimaryDarkBlue}},[_vm._v(_vm._s(_vm.$csBasicsIcons.informationNew)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.$t('adminPermission')))])],1)],1):_vm._e(),(!_vm.containsAdminPermission)?_c('v-list-item',{staticClass:"pa-0 mb-n2"},[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.$t('applicationAccess')))])],1):_vm._e(),(!_vm.containsAdminPermission)?_c('treeselect',{attrs:{"append-to-body":true,"backspace-removes":false,"clearable":false,"default-expand-level":Infinity,"delete-removes":false,"disabled":_vm.isReadOnly,"max-height":300,"multiple":true,"no-options-text":_vm.$t('noOptions'),"no-results-text":_vm.$t('noResult'),"options":_vm.applicationRightsTree,"placeholder":_vm.$t('select'),"sort-value-by":"INDEX"},on:{"deselect":function($event){return _vm.dirtyChanger(true)},"select":function($event){return _vm.dirtyChanger(true)}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return [(node.raw.tooltip)?_c('cs-tooltip',{attrs:{"open-tooltip-delay":150,"right":""},scopedSlots:_vm._u([{key:"tooltipActivator",fn:function(){return [_c('span',{staticClass:"option-label csSubHeading"},[_vm._v(_vm._s(_vm.$t(node.label)))])]},proxy:true},{key:"tooltipContent",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t(node.raw.tooltip)))])]},proxy:true}],null,true)}):_c('span',{staticClass:"option-label csSubHeading"},[_vm._v(_vm._s(_vm.$t(node.label)))])]}},{key:"value-label",fn:function(ref){
var node = ref.node;
return [_c('span',{staticClass:"value-label csText"},[_vm._v(_vm._s(_vm.translateList(node.raw.path)))])]}}],null,false,2187127383),model:{value:(_vm.selectedApplicationRights),callback:function ($$v) {_vm.selectedApplicationRights=$$v},expression:"selectedApplicationRights"}}):_vm._e(),(!_vm.containsAdminPermission)?_c('v-list-item',{staticClass:"pt-5 pl-0 pr-0 mb-n2"},[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.$t('featureRights')))])],1):_vm._e(),(!_vm.containsAdminPermission)?_c('treeselect',{attrs:{"backspace-removes":false,"clearable":false,"default-expand-level":1,"delete-removes":false,"disabled":_vm.isReadOnly,"multiple":true,"no-options-text":_vm.$t('noOptions'),"no-results-text":_vm.$t('noResult'),"options":_vm.featureRightsTree,"placeholder":_vm.$t('select'),"sort-value-by":"INDEX"},on:{"deselect":function($event){return _vm.dirtyChanger(true)},"select":function($event){return _vm.dirtyChanger(true)}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return [(node.raw.tooltip)?_c('cs-tooltip',{attrs:{"open-tooltip-delay":150,"right":""},scopedSlots:_vm._u([{key:"tooltipActivator",fn:function(){return [_c('span',{staticClass:"option-label csSubHeading"},[_vm._v(_vm._s(_vm.$t(node.label)))])]},proxy:true},{key:"tooltipContent",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t(node.raw.tooltip)))])]},proxy:true}],null,true)}):_c('span',{staticClass:"option-label csSubHeading"},[_vm._v(_vm._s(_vm.$t(node.label)))])]}},{key:"value-label",fn:function(ref){
var node = ref.node;
return [_c('span',{staticClass:"value-label csText"},[_vm._v(_vm._s(_vm.translateList(node.raw.path)))])]}}],null,false,2187127383),model:{value:(_vm.selectedFeatureRights),callback:function ($$v) {_vm.selectedFeatureRights=$$v},expression:"selectedFeatureRights"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }