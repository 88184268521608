import gql from 'graphql-tag'

export const GET_USERS_QUERY = gql`
  query getUsers {
    getUsers {
      account_status
      created
      email
      phone_number
      family_name
      given_name
      groups
      last_modified
      username
      user_status
    }
  }
`
