import gql from 'graphql-tag'

export const UPDATE_ROLE_MUTATION = gql`
  mutation updateRole($SK: String!, $name: String!) {
    updateRole(roleattributes: { SK: $SK, name: $name }) {
      SK
      name
    }
  }
`
export const UPDATE_ROLE_VARIABLES = ['SK', 'name']
