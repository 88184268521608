import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import colors from '@content-software/cs-frontend-components/src/styles/csColorsStrings'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: colors['cs-primary-dark-blue'],
        error: colors['cs-accent-red'],
        info: colors['cs-primary-dark-blue'],
        warning: colors['cs-accent-orange'],
        success: colors['cs-accent-green']
      }
    }
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 640,
      md: 800,
      lg: 1264
    }
  }
})
